import React, { Component } from "react";
import { Row, Col, Container } from "react-bootstrap";
import PageWrapper from "../components/PageWrapper";

class Contact extends Component {
  componentDidMount() {
    var tag = document.createElement('script');
    tag.async = false;
    tag.src = 'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js';
    document.body.appendChild(tag);
  }

  render() {
    return (
      <>
        <PageWrapper
          headerConfig={{
            align: "right",
            button: "null", // cta, account, null
          }}
        >
          <div className="inner-banner pt-29 pb-md-11 bg-default-2">
            <Container>
              <Row className="justify-content-center pt-5">
                <Col lg="9" xl="8">
                  <div className="px-md-15 text-center">
                    <h2 className="title gr-text-2 mb-9">Contact us</h2>
                    <p className="gr-text-8 mb-13">
                      Do you have an interesting SaaS product? Let's bring it to the market and start generating serious revenue. 
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="pb-5 pb-md-33 bg-default-2 ">
            <Container>
              <Row className="justify-content-center">
                
                <Col xs="12">
                  <div className="login-form bg-white border-gray-3 px-8 pt-8 pb-9 px-sm-11 py-sm-11 shadow-1 rounded-10">
                      <div className="meetings-iframe-container" data-src="https://meetings.hubspot.com/bringthegrowth?embed=true"></div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </PageWrapper>
      </>
    );
  }
};
export default Contact;
